
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";
export default Vue.extend({
  name: "Create",

  data: () => ({
    rules,
    model: {} as any,
    errorMessages: {} as any
  }),

  methods: {
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as any).validate()) {
          await this.$API.roles().create(this.model);
          await this.$router.push("/roles");
          await this.$store.dispatch(
            "alert/showSuccess",
            "Запись успешно добавлена."
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
